.about-us {
  text-align: center;
  padding: 20px;
}

.about-us h1 {
  font-size: 42px;
  margin-bottom: 10px;
  white-space: pre-line;
  color: #196BDE;
}

.about-us h2 {
  font-size: 25px;
  margin-bottom: 10px;
}

.aboutus-main h1 {
  font-size: 42px;
  margin-bottom: 20px;
  padding: 140px;
  color: white;
  font-weight: 500;
}

.about-us p {
  font-size: 19px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.social-icons p {
  margin-right: 10px;
}

.social-icons a {
  display: inline-block;
  font-size: 24px;
  color: #333; /* Adjust the color as needed */
  margin: 0 5px; /* Adjust the horizontal spacing */
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff; /* Change the color on hover */
}

/* Styles for the contact form */
.contact-form {
  text-align: center;
  margin-top: 20px;
}

.contact-form h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.about-us h2 {
  font-size: 30px;
  margin-bottom: 10px;
  white-space: pre-line;
}

