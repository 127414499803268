/* src/ModalStyles.css */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.modal-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
}

.modal-content p {
  margin: 1.5rem 0;
  font-size: 1.1rem;
  color: #ddd;
}

.modal-content a {
  color: #93C5FD;
  text-decoration: none;
  font-weight: 500;
}

.modal-content a:hover {
  text-decoration: underline;
}

.modal-content button {
  background-color: #93C5FD;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #2E3B80;
}
