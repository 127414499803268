


/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
   
    font-size: 46px;
    line-height: 40px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: 75;
    color: #196BDE;
}
.star-icon {
    color: #37D39E;
  }
  .yellow-to-gray {
    background: linear-gradient(90deg, #EAB305 80%, gray 20%);
    -webkit-background-clip: text;
    color: transparent;
  }
  .yellow-to-gray1 {
    background: linear-gradient(90deg, #EAB305 60%, gray 40%);
    -webkit-background-clip: text;
    color: transparent;
  }
  .yellow-to-gray2 {
    background: linear-gradient(90deg, #EAB305 70%, gray 30%);
    -webkit-background-clip: text;
    color: transparent;
  }
  .yellow-to-gray3 {
    background: linear-gradient(90deg, #EAB305 40%, gray 60%);
    -webkit-background-clip: text;
    color: transparent;
  }
  
/* App.css or a separate CSS file */

.photo-gallery-container {
    padding: 10px;
    margin: auto;
  }
  
  .carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .thumbnail-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .thumbnail {
    margin: 5px;
    border-radius: 8px;
    overflow: hidden; /* Ensures the border radius affects the image */
  }
  
  .thumbnail-image {
    width: 100px;
    height: auto;
    border-radius: 8px; /* Adds border radius to images */
    cursor: pointer;
    transition: border 0.3s, transform 0.3s;
  }
  
  .thumbnail-image:hover {
    transform: scale(1.05);
  }
  
  .thumbnail.active .thumbnail-image {
    border: 2px solid blue;
  }
  
